<template>
  <div class="breadcrumb">
    <div>
      <span v-for="(item, index) in list" :key="index" class="breadcrumb-item">
        <a-icon v-if="index === 0" type="home" class="divider" />
        <a
          class="breadcrumb-title"
          :class="
            item.path !== null && item.path !== $route.name ? 'default' : ''
          "
          @click="onEnter(item.path)"
        >
          {{ item.name }}
        </a>
        <span v-if="index < list.length - 1" class="divider">/</span>
      </span>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      userMenu: [],
      curSubmenus: [],
      topMenuId: "",
      subMenuId: "",
      isCurrent: false
    };
  },
  mounted() {
    this.userMenu = this.$store.state.userMenu;
    this.curSubmenus = this.$store.state.curSubmenus;
    this.topMenuId = this.$store.state.selectedTopMenuId;
    this.subMenuId = this.$store.state.selectedSubmenuId;
    const topMenu = this.userMenu.find(item => {
      if (item.menuId === this.topMenuId) {
        return item;
      }
    });
    if (topMenu) {
      this.list.push(topMenu);
    }
    if (this.curSubmenus && this.curSubmenus.length > 0) {
      for (let i = 0; i < this.curSubmenus.length; i = i + 1) {
        if (this.curSubmenus[i].menuId === this.subMenuId) {
          this.list.push(this.curSubmenus[i]);
          break;
        } else {
          if (this.curSubmenus[i].subMenus) {
            const arr = this.curSubmenus[i].subMenus;
            const subMenu = arr.find(ele => {
              if (ele.menuId === this.subMenuId) return ele;
            });
            if (subMenu) {
              this.list.push(this.curSubmenus[i]);
              this.list.push(subMenu);
            }
          }
        }
      }
    }
  },
  methods: {
    onEnter(name) {
      this.$router.push({ name: name });
    }
  }
};
</script>

<style scoped>
.breadcrumb {
  display: flex;
  align-items: center;
}

.divider {
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.45);
}

.breadcrumb-title {
  color: rgba(0, 0, 0, 0.45);
  cursor: auto;
}

.default:hover {
  cursor: pointer;
  color: #1890ff;
}

.breadcrumb-item:first-child a:hover {
  color: rgba(0, 0, 0, 0.45);
  cursor: auto;
}

.breadcrumb-item:last-child a {
  color: rgba(0, 0, 0, 0.65);
  cursor: auto;
}
</style>
