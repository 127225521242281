<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <div class="main__container">
      <div class="container__left">
        <div class="title">
          <img
            src="../../assets/imgs/financial/icon_money_one.png"
            alt="icon"
          />
          价值包收益：
        </div>
        <div class="account">
          <span class="account__unit">
            ¥
            <span class="account__num">{{ balance }}</span>
          </span>
        </div>
        <a-space class="operation" :size="60">
          <a @click="onReceiptAndPayRecords">查看收付款记录</a>
        </a-space>
      </div>
      <!--      <div class="container__right">-->
      <!--        <div class="title">-->
      <!--          <img src="../../assets/imgs/financial/icon_money.png" alt="icon" />-->
      <!--          机构收益-->
      <!--        </div>-->
      <!--        <a-row>-->
      <!--          <a-col :span="6">-->
      <!--            <div class="statistic__item">-->
      <!--              <div class="account">-->
      <!--                <span class="account__unit">-->
      <!--                  ¥-->
      <!--                  <span class="account__num">200</span>-->
      <!--                </span>-->
      <!--              </div>-->
      <!--              <div class="item__title">今日收益</div>-->
      <!--            </div>-->
      <!--          </a-col>-->
      <!--          <a-col :span="12">-->
      <!--            <div class="statistic__item">-->
      <!--              <div class="account">-->
      <!--                <span class="account__unit">-->
      <!--                  ¥-->
      <!--                  <span class="account__num">200,000</span>-->
      <!--                </span>-->
      <!--              </div>-->
      <!--              <div class="item__title">当月收益</div>-->
      <!--            </div>-->
      <!--          </a-col>-->
      <!--          <a-col :span="6">-->
      <!--            <div class="statistic__item">-->
      <!--              <div class="account">-->
      <!--                <span class="account__unit">-->
      <!--                  ¥-->
      <!--                  <span class="account__num">200,000</span>-->
      <!--                </span>-->
      <!--              </div>-->
      <!--              <div class="item__title">累计收益</div>-->
      <!--            </div>-->
      <!--          </a-col>-->
      <!--        </a-row>-->
      <!--      </div>-->
    </div>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { checkAccountBalance } from "@/services/PayService";

export default {
  name: "AccountBalanceFactory",
  components: {
    Breadcrumb
  },
  data() {
    return {
      balance: 0
    };
  },
  mounted() {
    // this.loadAccountBalance();
  },
  methods: {
    //查看收付款记录
    onReceiptAndPayRecords() {
      this.$router.push({ name: "ReceiptAndPayRecords" });
    },
    // 充值
    onRecharge() {
      this.$router.push({ name: "Recharge" });
    },
    // 查询账户余额
    loadAccountBalance() {
      checkAccountBalance(localStorage.getItem("corporationId")).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data.acctInfo && data.acctInfo.length > 0) {
            this.balance = data.acctInfo[0].balanceAmt;
          }
        }
      });
    },
    // 跳转到查看明细页面
    toCountDetail() {
      this.$router.push({ name: "AmountDetail" });
    },
    // 跳转查看手续费账户明细
    toCommissionDetail() {
      this.$router.push({ name: "CommissionAccountDetail" });
    }
  }
};
</script>

<style scoped>
.main__container {
  margin: 24px;
  display: flex;
}

.container__left {
  width: 50%;
  margin-right: 36px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(16, 16, 16, 1);
  box-sizing: border-box;
  padding: 20px;
}

.container__right {
  flex: 1;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(16, 16, 16, 1);
  box-sizing: border-box;
  padding: 20px;
}

.title {
  display: flex;
  align-items: center;
  color: rgba(16, 16, 16, 1);
  font-size: 16px;
  padding: 0;
  margin-bottom: 35px;
}

.title img {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.account {
  display: flex;
  justify-content: center;
}

.account__unit {
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  font-weight: bold;
}

.account__num {
  color: rgba(16, 16, 16, 1);
  font-size: 24px;
  font-weight: bold;
}

.operation {
  display: flex;
  justify-content: center;
  margin: 20px 0 10px 0;
}

.statistic__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item__title {
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  margin-top: 20px;
}
</style>
