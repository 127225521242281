import { apiClient } from "@/services/HttpService";

// 充值
export function recharge(params) {
  return apiClient.post(`/pay`, params);
}

// 手续费计算
export function calculateFeeCount(params) {
  return apiClient.post(`/pay/feeCount`, params);
}

// 获取绑定的银行卡
export function fetchBindBankCard() {
  return apiClient.get(`/bank-card/card-list`);
}

// 查询账户余额
export function checkAccountBalance(corporationId) {
  return apiClient.get(
    `/pay/tradeAcctpaymentBalanceQuery?corporationId=${corporationId}`
  );
}

// Alipay二维码
export function fetchAlipayQrCode(params) {
  return apiClient.post(`/pay/tradePayment/alipay`, params);
}

// 获取alipay支付结果
export function fetchAlipayResult(params) {
  return apiClient.post(`/pay/tradePayment/query`, params);
}

// 微信订单创建
export function wechatOrderCreate(params) {
  return apiClient.post(`/pay/wechat/order/create`, params);
}

// 微信支付状态查询
export function checkWechatPayStatus(orderId) {
  return apiClient.get(`/pay/wechat/order/query?orderId=${orderId}`);
}

// 查询充值详情
export function checkRechargeDetail(orderId) {
  return apiClient.get(`/huifu/order/${orderId}`);
}

// 查看明细页面-->获取订单列表
export function getOrderList(params) {
  let url = `/huifu/order/list?pageSize=${params.pageSize}&pageNum=${params.pageNum}&orderId=${params.orderId}&startTime=${params.startTime}&endTime=${params.endTime}&dealType=${params.dealType}&orderStatus=${params.orderStatus}&isMch=${params.isMch}&corporationId=${params.corporationId}`;
  return apiClient.get(url);
}

// 账户转账
export function transferAccount(paymentAmount) {
  return apiClient.post(`pay/tradeAcctpaymentPayByTopUp/${paymentAmount}`);
}

// 查询手续费账户明细
export function checkCommissionAccountDetail(params) {
  return apiClient.get(
    `/huifu/order/topUp/list?startTime=${params.startTime}&endTime=${params.endTime}&pageNum=${params.pageNum}&pageSize=${params.pageSize}&hfSeqIdKeyWord=${params.hfSeqIdKeyWord}&payerKeyWord=${params.payerKeyWord}&payeeCorporationId=${params.payeeCorporationId}&orderStatus=${params.orderStatus}`
  );
}
